module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Valoremos El Trabajo Artesanal","short_name":"Valoremos El Trabajo Artesanal","start_url":"/","background_color":"#333","display":"minimal-ui","theme_color":"#333","icon":"src/assets/img/icon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
